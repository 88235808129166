<template>
  <div class="container">
    <div class="content-simple">
      <h1 class="mt-md-3">ORC Market - Updated Analysis (2017)</h1>

      <p>
        <small
          >By
          <a href="http://ca.linkedin.com/in/thomastartiere"
            >Thomas Tarti&egrave;re</a
          >. Last update : October, 2017</small
        >
      </p>

      <div class="row">
        <p>
          This work was presented in September 2017 at the IV International
          Seminar on ORC Power Systems, in Milano (Italy). An updated analysis
          was developped in collaboration with Marco Astolfi and published in
          Energy Procedia. The
          <a href="/docs/WorldOverview2017.pdf"
            >full article is available here</a
          >.
        </p>
      </div>

      <h1 class="mt-md-3">ORC Market : A World Overview (2016)</h1>

      <p>
        <small
          >By
          <a href="http://ca.linkedin.com/in/thomastartiere"
            >Thomas Tarti&egrave;re</a
          >. Last update : August, 16th, 2016</small
        >
      </p>

      <ul id="faq">
        <li><a href="#introduction">Introduction</a></li>
        <li><a href="#current_situation">Current situation</a></li>
        <li><a href="#installed_capacity">Total installed capacity</a></li>
        <li><a href="#geographical">Geographical breakdown</a></li>
        <li><a href="#heat_recovery">Heat Recovery Applications</a></li>
        <li><a href="#conclusion">Conclusion</a></li>
        <li><a href="#disclaimer">Disclaimer</a></li>
      </ul>

      <div class="row">
        <h2 id="introduction">Introduction</h2>
        <p>
          The Organic Rankine Cycle (ORC) technology is a way to convert heat
          into electricity. Its main applications are distributed electricity
          generation from renewable heat sources (geothermal, biomass, solar)
          and industrial energy efficiency (heat recovery from industrial
          processes).
        </p>

        <p>
          This report gives an overview of the installed ORC capacity over the
          years for different markets, all over the world. The data comes from
          the websites and list of references of ORC manufacturers. When
          possible, multiple sources (press articles, scientific papers) have
          been compared.
        </p>

        <p>The following ORC manufacturers have been included :</p>
      </div>

      <b-row>
        <b-col md="4" sm="6">
          <ul>
            <li><a href="http://www.abb.com/">ABB</a></li>
            <li>
              <a href="http://www.adoratec.com/companyprofilnav.html"
                >Adoratec / Maxxtec</a
              >
            </li>
            <li><a href="http://e-rational.net/">BEP E-Rational</a></li>
            <li><a href="https://www.calnetix.com/">Calnetix</a></li>
            <li>
              <a href="http://heatrecoverysolutions.com/"
                >CETY / Heat Recovery Solutions</a
              >
            </li>
            <li>
              <a href="http://www.durr-cyplan.com/">D&uuml;rr Cyplan</a>
            </li>
            <li><a href="http://www.enertime.com/en/home">Enertime</a></li>
            <li><a href="http://www.enex.is">ENEX</a></li>
          </ul>
        </b-col>
        <b-col md="4" sm="6">
          <ul>
            <li><a href="http://www.enogia.com/">ENOGIA</a></li>
            <li>Enreco</li>
            <li><a href="http://www.exergy-orc.com/">Exergy</a></li>
            <li><a href="https://electratherm.com/">Electratherm</a></li>

            <li>
              <a href="http://powergen.gepower.com/applications/chp.html"
                >General Electric</a
              >
            </li>
            <li><a href="http://www.gmk.info/">GMK</a></li>
            <li>
              <a href="http://www.johnsoncontrols.com/">Johnson Control</a>
            </li>
            <li>
              <a
                href="http://www.kaishancompressor.com/Industry-Products/Screw-Expander-Power-Stations/Organic-Rankine-Cycle-Power-Plant-Expansion-Screw/Organic-Rankine-Cycle-Power-Plant-Expansion-Screw.html"
                >Kaishan</a
              >
            </li>
          </ul>
        </b-col>
        <b-col md="4" sm="6">
          <ul>
            <li>
              <a href="http://www.opcon.se/web/oes_en.aspx"
                >Opcon Energy Systems</a
              >
            </li>
            <li><a href="http://www.ormat.com">ORMAT</a></li>

            <li><a href="http://www.tas.com/">TAS</a></li>
            <li><a href="http://www.tmeic.com/TMEIC%20Global">TMEIC</a></li>
            <li><a href="http://www.triogen.nl/">Triogen</a></li>
            <li>
              <a href="http://www.turboden.eu/en/home/index.php">Turboden</a>
            </li>
            <li>
              <a
                href="http://www.pw.utc.com/Press/Story/20121017-1200/2012/All%20Categories"
                >UTC Power</a
              >
            </li>
            <li>
              <a href="http://www.g-tet.com/">gT - Energy Technologies</a>
            </li>
          </ul>
        </b-col>
      </b-row>

      <div class="row">
        <p>
          In addition there are other manufacturers that have not published
          their list of references and declined to take part to the survey.
          Therefore this work does not pretend to be exhaustive, even if most of
          the ORC power plants have been taken into account.
        </p>
        <p>
          This survey aims at presenting the progress of the ORC technology over
          the last decades, for commercial applications. Therefore, lab-scale
          prototypes have not been included. In addition, references with total
          installed capacity lower than 50 kWel have been discounted. We have
          considered that below this threshold the technologies used are
          different and involve different stakeholders and markets.
        </p>
      </div>

      <div class="row">
        <h2 id="current_situation">Current situation</h2>
        <div class="current_numbers">
          <ul>
            <li>Total installed capacity : 2749.1 MWel, in 563 power plants</li>
            <li>New capacity planned : 523.6 MWel in 75 plants</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="data-img">
            <img
              src="@/assets/images/total_installed_capacity_per_application.png"
              style="max-width: 600px"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="data-img">
            <img
              src="@/assets/images/market_share.png"
              style="max-width: 600px"
            />
          </div>
        </div>

        <p>
          Geothermal power plants contribute to 76.5% of all ORC installed
          capacity in the world. Biomass follows with 10.7%. Heat recovery from
          gas turbines (compressor stations along pipelines) and stationary
          Diesel power plants has a similar share with 8.5%. All other heat
          recovery applications represent 4.2%, while solar thermodynamic
          remains neglectible.
        </p>
        <p>
          ORMAT is the world leader for the total installed capacity (65.7%),
          followed by the Italian companies Turboden (12.6%) and Exergy (9.8%).
        </p>
      </div>

      <div class="row">
        <h2 id="installed_capacity">Installed Capacity over the years</h2>
        <p>
          The following figures give the installed capacity and number of
          projects that were commissioned per year and per application.
        </p>

        <b-row>
          <b-col lg="6" md="6" sm="12">
            <img src="@/assets/images/total_installed_capacity_per_year.png" />
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <img src="@/assets/images/total_installed_projects_per_year.png" />
          </b-col>
        </b-row>
        <p>
          The market peaked in 2013, with 325.5 MWel of new capacity. Since
          then, we can notice a slo down in 2014 and 2015, with roughly 250 MWel
          being installed each year. This may be due to a decline in geothermal
          applications, related to low natural gas prices and competition
          against solar and wind (especially on the US market). The heat
          recovery market has grown significantly over the last three years,
          from 14.5 MW in 2013 to 116.8 MW in 2015.
        </p>
        <div class="data-img">
          <img
            src="@/assets/images/total_installed_capacity_per_year_exgeo.png"
          />
        </div>
      </div>

      <div class="row">
        <h2 id="geographical">Geographical breakdown</h2>
        <div class="data-img">
          <img src="@/assets/images/total_installed_capacity_per_country.png" />
        </div>
        <p>
          The United States has the largest installed capacity per country,
          followed by Turkey, New Zealand, and the Philippines. These four
          countries benefit from abundant geothermal resources. Germany and
          Italy are the most significant for biomass, mainly due to favorable
          policies, followed by Canada and Austria. The biggest shares for heat
          recovery are in the USA and Canada, mainly for waste heat recovery
          from compressor stations along gas pipelines.
        </p>

        <p>
          The following figures look only at projects that were commissioned in
          2015 or have been planned. Of course, all new projects for 2016-2017
          haven't been announced, but we can see that most of the new geothermal
          development is focused on Turkey, with 9 new projects. The large share
          of Indonesia is caused by a single new
          <a
            href="http://www.ormat.com/news/latest-items/sarulla-consortium-signs-117-billion-financing-agreements-330-mw-sarulla-geotherma"
            >330 MWel ORMAT project in Indonesia</a
          >. Biomass development continues in Italy, Russia, Canada and the
          United Kingdom. However, the average size of biomass ORC installed in
          Italy is below 1 MWel, while new plants in Canada and Russia have an
          average power output above 4 MWel, mostly for saw mills.
        </p>

        <div class="data-img">
          <img
            src="@/assets/images/installed_capacity_per_country_recent.png"
          />
        </div>

        <div class="data-img">
          <img src="@/assets/images/projects_per_country_recent.png" />
        </div>
      </div>

      <div class="row">
        <h2 id="heat_recovery">Heat Recovery applications</h2>
        <div class="data-img">
          <img
            style="max-width: 1000px"
            src="@/assets/images/heat_recovery.png"
          />
        </div>
        <p>
          With 349.1 MWel of installed capacity in the world, and 18.5 MW of new
          capacity in construction (10 projects), the heat recovery market is
          still at an early stage but has long passed the demo/prototype phase.
          The main application is waste heat recovery from Diesel engines or gas
          turbines, with 66.8% of the installed capacity (41 projects). These
          units are mostly installed on compressor stations along gas pipeline,
          or on Diesel power plants. Cement & lime plants are the first
          industrial application, with 10 projects and a total installed power
          of 29 MWel. Waste to Energy (incineration) and metal industries
          (steel, aluminum, foundries...), have similar shares with respectively
          12 and 24 projects.
        </p>

        <p>
          The installed capacity for cement, metal and glass industries is still
          very small compared to its potential, as stated in many studies:
        </p>

        <ul>
          <li>
            <a
              href="http://web.ornl.gov/sci/buildings/docs/ORNL%20TM-2014-620%20Waste%20Heat%20to%20Power.pdf"
              >Waste Heat to Power potential in the USA, report from Oak Ridge
              National Laboratory, 2015</a
            >
          </li>
          <li>
            <a href="https://china.lbl.gov/sites/all/files/lbnl-179618.pdf"
              >Waste Heat Recovery potential in China, report from Berkeley
              National Laboratory, 2015</a
            >
          </li>
          <li>
            <a
              href="http://www.hreii.eu/public/Annex%204.2.II%20EU%20paper%20def.pdf"
              >ORC Waste Heat Recovery in European Energy-Intensive industries,
              2013</a
            >
          </li>
        </ul>

        <p>
          Biogas and landfill gas engines together account for 6.2 MWel of
          installed capacity within 35 projects. Please note that projects below
          50 kWel have been ignored.
        </p>
      </div>

      <div class="row">
        <h2 id="conclusion">Conclusion</h2>

        <p>
          This survey shows the development of the ORC technology over the last
          decades. Without pretending to be exhaustive, most of the projects
          installed in the world have been taken into account. Geothermal power
          plants are still the main applications in terms of power output, but
          the last year have seen an increase in biomass and heat recovery
          applications.
        </p>
      </div>

      <div class="row">
        <h2 id="disclaimer">Disclaimer</h2>
        <p>
          The information contained in this website is for general information
          purposes only. While the author endeavours to keep the information up
          to date and correct, the author makes no representations or warranties
          of any kind, express or implied, about the completeness, accuracy,
          reliability, suitability or availability with respect to the website
          or the information, products, services, or related graphics contained
          on the website for any purpose. Any reliance you place on such
          information is therefore strictly at your own risk.
        </p>

        <p>
          In no event the author will we be liable for any loss or damage
          including without limitation, indirect or consequential loss or
          damage, or any loss or damage whatsoever arising from loss of data or
          profits arising out of, or in connection with, the use of this
          website.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analysis",
};
</script>

<style scoped>
img {
  max-width: 100%;
}
</style>