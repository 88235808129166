<template>
  <div>
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard"
};
</script>

<style scoped>
</style>